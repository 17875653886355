.individual-student-report {
  .header-container {
    display: block !important;
    border: 0 !important;
    &.student-competencies-activate {
      display: block !important;
      .header-left,
      .header-right,
      .student-info-container {
        display: none !important;
      }
      .report-period-selector {
        .report-periods,
        .active-period,
        .custom-range-shower {
          display: none !important;
        }
        .report-download {
          display: block !important;
          .print-preview {
            display: none !important;
          }
          .individual-student-report-preview {
            .individual-report-container {
              .print-preview {
                display: none !important;
                display: flex;
              }
              .tooltip {
                display: none !important;
              }
              color: $black-bold !important;
              .report-container {
                display: block !important;
                width: 100% !important;
                height: 100% !important;
                position: absolute;
                left: 0;
                top: 0;
                .header-penal {
                  .header-info {
                    display: grid;
                    grid-template-columns: auto auto 80px;
                    gap: 10px;
                    margin-bottom: 30px;
                    border-bottom: 2px solid;
                    .student-details {
                      display: grid;
                      grid-template-columns: 50px auto;
                      column-gap: 5px;
                      align-items: center;

                      .thumbnail-container {
                        width: 47px;
                        height: 47px;
                        background-size: 47px 47px !important;
                        display: block;
                        border-radius: 50%;
                        border: none;
                      }
                      .student-name {
                        text-transform: capitalize;
                        opacity: 0.9;
                        font-size: 13px;
                        color: $black-bold;
                        display: flex;
                        align-items: center;
                      }
                    }
                    .tenan-info {
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      .tenant-logo {
                        display: block;
                        width: 65px;
                        height: 65px;
                        background-repeat: no-repeat !important;
                        background-size: contain !important;
                        background-position: center !important;
                        border-radius: 25px;
                      }
                    }

                    .report-info {
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;
                      align-items: flex-end;
                      line-height: 1.5;
                      .tenant-name {
                        text-transform: capitalize !important;
                        font-weight: bold;
                        font-size: 16px;
                      }
                    }
                  }
                }
                .body-penal {
                  .report-overview {
                    .title {
                      padding: 10px 0px;
                      font-weight: bold;
                      font-size: 16px;
                    }
                    .student-report,
                    .competencies-report {
                      .class-progress-report-table {
                        width: 100%;
                        tr {
                          td,
                          th {
                            border: 1px solid $black-bold;
                            border-collapse: collapse;
                            padding: 5px;
                            text-align: center;
                            font-size: 15px;
                          }
                        }
                      }
                    }
                    .student-report {
                      .class-progress-report-table {
                        tr {
                          td {
                            th {
                              height: 55px;
                            }
                          }
                        }
                      }
                    }
                    .competencies-report {
                      margin: 30px 20px;
                      .class-progress-report-table {
                        page-break-inside: auto;
                        tbody {
                          tr {
                            page-break-after: auto;
                            border-left: 1px solid;
                            border-right: 1px solid;
                            &:last-child {
                              border-bottom: 1px solid;
                            }
                            td {
                              text-align: left;
                              font-size: 13px;
                              border: none !important;
                              padding: 0 10px;
                              &:first-child {
                                border-right: 1px solid !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .report-data-by-date {
                    .title {
                      padding: 10px 0px;
                      font-weight: bold;
                      font-size: 16px;
                    }
                    .student-competency-report {
                      border-bottom: 2px solid $white;
                      page-break-inside: avoid;
                      &:last-child {
                        border-bottom: 0;
                      }
                      .report-date {
                        font-size: 13px;
                        font-weight: bold;
                      }
                      .competency-info {
                        .competency-card-panel {
                          display: grid;
                          width: 375px;
                          margin: 8px;
                          border-radius: 4px;
                          background-color: $table-border-color !important;
                          grid-template-rows: auto 1fr;
                          box-shadow: 0 2px 4px 0
                            $navbar-default-toggle-icon-bar-bg;
                          height: max-content;
                          page-break-inside: avoid;
                          page-break-before: always;
                          .code-info {
                            display: grid;
                            column-gap: 4px;
                            align-items: center;
                            padding: 5px;
                            height: max-content;
                            .display-code {
                              display: grid;
                              grid-column: 1;
                              font-size: 11px;
                              .code {
                                font-weight: bold;
                                display: flex;
                                .group-competency {
                                  .spliter {
                                    padding-right: 5px;
                                  }
                                  &:last-child {
                                    .spliter {
                                      display: none;
                                    }
                                  }
                                }
                              }
                              .title {
                                font-size: 12px;
                                color: $dark-400;
                                padding: 5px 0px;
                              }
                            }
                          }
                          .activity-row {
                            display: grid;
                            min-height: 48px;
                            border-top: 1px solid $gray-ultra-light;
                            grid-template-columns: 40px 1fr 40px 40px 58px 48px;
                            cursor: pointer;
                            padding: 5px;

                            .icon-container {
                              display: flex;
                              align-items: center;
                              justify-content: center;

                              .type {
                                background-repeat: no-repeat !important;
                                width: 20px;
                                height: 20px;
                                background-size: 100% !important;
                                display: block;
                                background-position: center !important;

                                &.assessment,
                                .assessment-external {
                                  background-image: url("gooru/images/add_assessment.svg") !important;
                                }

                                &.collection,
                                .collection-external {
                                  background-image: url("gooru/images/add_collection.svg") !important;
                                }

                                &.offline-activity {
                                  background-image: url("gooru/images/offline.svg") !important;
                                }
                              }
                            }

                            .resource-count,
                            .question-count {
                              width: 40px;
                              display: inline-grid;
                              align-items: center;
                              grid-template-columns: 50% 50%;
                              justify-items: center;
                              .icon {
                                width: 20px;
                                height: 20px;
                                background-repeat: no-repeat !important;
                                background-size: 20px 20px !important;
                                opacity: 0.8;
                                &.question {
                                  background-image: url("gooru/images/add_question.svg") !important;
                                }
                                &.resource {
                                  background-image: url("gooru/images/add_resource_icon.svg") !important;
                                }
                              }
                            }

                            .title {
                              display: grid;
                              align-items: center;
                              padding: 0;
                              .title-text {
                                font-size: 12px;
                                line-height: 1.5;
                                font-weight: normal;
                              }
                            }

                            .timespent {
                              line-height: 1;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-size: 12px;
                            }

                            .percentage {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-size: 13px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .skyline-report {
                    page-break-inside: avoid;
                    .skyline-summery {
                      .date {
                        font-size: 14px;
                        font-weight: bold;
                      }
                    }
                    .skyline-overview {
                      .chart {
                        page-break-inside: avoid;
                        display: grid;
                        grid-template-columns: auto auto;
                        .proficiency-chart {
                          .learner-proficiency-domain-matrix {
                            .actions {
                              .grade-selector {
                                .active-grade-list {
                                  .active-grade {
                                    span {
                                      .close {
                                        display: none;
                                      }
                                    }
                                  }
                                }
                              }
                              .fwc-panel,
                              .toggle-chart {
                                display: none;
                              }
                            }
                            .chart-area {
                              .matrix-chart {
                                .chart-container {
                                  .domain-chart-container {
                                    .scrollable-chart {
                                      height: max-content !important;
                                      #render-proficiency-matrix {
                                        height: max-content !important;
                                        #skyline-group,
                                        #gradeline-group {
                                          filter: url(#back-shadow);
                                          polyline {
                                            fill: none;

                                            &.hidden-line {
                                              display: none;
                                            }
                                          }

                                          &#skyline-group polyline {
                                            stroke-width: 6px;
                                            stroke: $white !important;
                                            animation: polylineDash 8s linear
                                              forwards;
                                          }

                                          &#gradeline-group polyline {
                                            stroke-width: 3px;
                                            stroke: #8fd383 !important;
                                            animation: polylineDash 4s linear
                                              forwards;
                                          }

                                          @keyframes polylineDash {
                                            to {
                                              stroke-dashoffset: 0;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        .competencies-list {
                          margin: auto;
                          .subject-name {
                            font-weight: bold;
                          }
                          .domain-name {
                            display: flex;
                            padding-left: 15px;
                            span {
                              padding: 3px;
                            }
                          }
                        }
                      }
                      .proficiency-tags {
                        .last-updated {
                          text-align: right;
                          margin-top: 15px;
                          margin-right: 30px;
                          font-size: 12px;
                        }
                        .proficiency-legends {
                          margin: 15px auto auto;
                          width: 100%;
                          display: grid;
                          grid-template-columns: repeat(6, 1fr);
                          justify-content: space-around;
                          .legend {
                            display: flex;
                            justify-content: center;
                            .box {
                              display: block;
                              width: 15.6px;
                              height: 15.6px;
                              transform: rotate(90deg);
                              margin-right: 8.4px;
                            }
                            .box-class-grade-line {
                              width: 18px;
                              height: 5px;
                              margin-top: 4px;
                              box-shadow: 0 2px 4px 0
                                $navbar-default-toggle-icon-bar-bg !important;
                              margin-right: 8.4px;
                            }
                            .box-skyline {
                              width: 18px;
                              height: 10px;
                              box-shadow: 0 2px 4px 0
                                $navbar-default-toggle-icon-bar-bg !important;
                              margin-right: 8.4px;
                            }
                            .title {
                              line-height: 13px;
                              font-size: 12px;
                              color: $elephant-dark !important;
                            }
                            &.mastered {
                              .box {
                                background-color: $blue-mastery !important;
                              }
                            }
                            &.in-progress {
                              .box {
                                background-color: $blue-in-progress !important;
                              }
                            }
                            &.not-started {
                              .box {
                                background-color: $not-started !important;
                              }
                            }
                            &.show-gut {
                              .box {
                                background-image: linear-gradient(
                                  to bottom,
                                  $gray-82,
                                  $dark-400
                                ) !important;
                              }
                            }
                            &.skyline {
                              .box-skyline {
                                background-color: $white !important;
                              }
                            }
                            &.class-grade-line {
                              .box-class-grade-line {
                                background-color: #8fd383 !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .body-container {
    display: none !important;
  }
}

.student_class {
  .gru-study-navbar,
  .gru-student-navbar,
  .gru-study-timer {
    display: none;
  }
  .content {
    .milestone-course-map-view,
    .course-map,
    .rescope-content-preview,
    .title-container,
    .bar-charts {
      display: none !important;
    }
    .gru-student-class-progress-report-popup {
      overflow-y: inherit !important;
      .class-progress-report-container {
        margin: 0px;
        width: 100%;
        height: 100%;
        font-size: 10px;
        overflow-y: inherit !important;
        .print-preview {
          display: none !important;
        }
        .tooltip {
          display: none !important;
        }

        .header-container {
          border: 0;
          .student-details,
          .class-name,
          .datepicker {
            display: none;
          }
          .report-period-selector {
            display: block !important;
            .active-period,
            .date-range-picker-container {
              display: none !important;
            }
            .report-download {
              width: 100%;
              height: 100%;

              .individual-report-container {
                display: flex;
                width: 100%;
              }
              .report-container {
                display: block !important;
                width: 100% !important;
                height: 100% !important;
                position: absolute;
                left: 0;
                top: 0;
                .header-penal {
                  .header-info {
                    display: grid;
                    grid-template-columns: auto auto 80px;
                    gap: 10px;
                    margin-bottom: 30px;
                    border-bottom: 2px solid;
                    .student-details {
                      display: grid;
                      grid-template-columns: 50px auto;
                      column-gap: 5px;
                      align-items: center;

                      .thumbnail-container {
                        width: 47px;
                        height: 47px;
                        background-size: 47px 47px !important;
                        display: block;
                        border-radius: 50%;
                        border: none;
                      }
                      .student-name {
                        text-transform: capitalize;
                        opacity: 0.9;
                        font-size: 13px;
                        color: $black-bold;
                        display: flex;
                        align-items: center;
                      }
                    }
                    .tenan-info {
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      .tenant-logo {
                        display: block;
                        width: 65px;
                        height: 65px;
                        background-repeat: no-repeat !important;
                        background-size: contain !important;
                        background-position: center !important;
                        border-radius: 25px;
                      }
                    }

                    .report-info {
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;
                      align-items: flex-end;
                      line-height: 1.5;
                      .tenant-name {
                        text-transform: capitalize !important;
                        font-weight: bold;
                        font-size: 16px;
                      }
                    }
                  }
                }
                .body-penal {
                  .report-overview {
                    .title {
                      padding: 10px 0px;
                      font-weight: bold;
                      font-size: 16px;
                    }
                    .student-report,
                    .competencies-report {
                      .class-progress-report-table {
                        width: 100%;
                        tr {
                          td,
                          th {
                            border: 1px solid black;
                            border-collapse: collapse;
                            padding: 5px;
                            text-align: center;
                            font-size: 15px;
                          }
                        }
                      }
                    }
                    .student-report {
                      .class-progress-report-table {
                        tr {
                          td {
                            th {
                              height: 55px;
                            }
                          }
                        }
                      }
                    }
                    .competencies-report {
                      margin: 30px 20px;
                      .class-progress-report-table {
                        page-break-inside: auto;
                        tbody {
                          tr {
                            page-break-after: auto;
                            border-left: 1px solid;
                            border-right: 1px solid;
                            &:last-child {
                              border-bottom: 1px solid;
                            }
                            td {
                              text-align: left;
                              font-size: 13px;
                              border: none !important;
                              padding: 0 10px;
                              &:first-child {
                                border-right: 1px solid !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .report-data-by-date {
                    .title {
                      padding: 10px 0px;
                      font-weight: bold;
                      font-size: 16px;
                    }
                    .student-competency-report {
                      border-bottom: 2px solid $white;
                      page-break-inside: avoid;
                      &:last-child {
                        border-bottom: 0;
                      }
                      .report-date {
                        font-size: 13px;
                        font-weight: bold;
                      }
                      .competency-info {
                        .competency-card-panel {
                          display: grid;
                          width: 375px;
                          margin: 8px;
                          border-radius: 4px;
                          background-color: $table-border-color !important;
                          grid-template-rows: auto 1fr;
                          box-shadow: 0 2px 4px 0
                            $navbar-default-toggle-icon-bar-bg;
                          height: max-content;
                          page-break-inside: avoid;
                          page-break-before: always;
                          .code-info {
                            display: grid;
                            column-gap: 4px;
                            align-items: center;
                            padding: 5px;
                            height: max-content;
                            .display-code {
                              display: grid;
                              grid-column: 1;
                              font-size: 11px;
                              .code {
                                font-weight: bold;
                                display: flex;
                                .group-competency {
                                  .spliter {
                                    padding-right: 5px;
                                  }
                                  &:last-child {
                                    .spliter {
                                      display: none;
                                    }
                                  }
                                }
                              }
                              .title {
                                font-size: 12px;
                                color: $dark-400;
                                padding: 5px 0px;
                              }
                            }
                          }
                          .activity-row {
                            display: grid;
                            min-height: 48px;
                            border-top: 1px solid $gray-ultra-light;
                            grid-template-columns: 40px 1fr 40px 40px 58px 48px;
                            cursor: pointer;
                            padding: 5px;

                            .icon-container {
                              display: flex;
                              align-items: center;
                              justify-content: center;

                              .type {
                                background-repeat: no-repeat !important;
                                width: 20px;
                                height: 20px;
                                background-size: 100% !important;
                                display: block;
                                background-position: center !important;

                                &.assessment,
                                .assessment-external {
                                  background-image: url("gooru/images/add_assessment.svg") !important;
                                }

                                &.collection,
                                .collection-external {
                                  background-image: url("gooru/images/add_collection.svg") !important;
                                }

                                &.offline-activity {
                                  background-image: url("gooru/images/offline.svg") !important;
                                }
                              }
                            }

                            .resource-count,
                            .question-count {
                              width: 40px;
                              display: inline-grid;
                              align-items: center;
                              grid-template-columns: 50% 50%;
                              justify-items: center;
                              .icon {
                                width: 20px;
                                height: 20px;
                                background-repeat: no-repeat !important;
                                background-size: 20px 20px !important;
                                opacity: 0.8;
                                &.question {
                                  background-image: url("gooru/images/add_question.svg") !important;
                                }
                                &.resource {
                                  background-image: url("gooru/images/add_resource_icon.svg") !important;
                                }
                              }
                            }

                            .title {
                              display: grid;
                              align-items: center;
                              padding: 0;
                              .title-text {
                                font-size: 12px;
                                line-height: 1.5;
                                font-weight: normal;
                              }
                            }

                            .timespent {
                              line-height: 1;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-size: 12px;
                            }

                            .percentage {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-size: 13px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .skyline-report {
                    page-break-inside: avoid;
                    .skyline-summery {
                      .date {
                        font-size: 14px;
                        font-weight: bold;
                      }
                    }
                    .skyline-overview {
                      .chart {
                        page-break-inside: avoid;
                        display: grid;
                        grid-template-columns: auto auto;
                        .proficiency-chart {
                          .learner-proficiency-domain-matrix {
                            .actions {
                              .grade-selector {
                                .active-grade-list {
                                  .active-grade {
                                    span {
                                      .close {
                                        display: none;
                                      }
                                    }
                                  }
                                }
                              }
                              .fwc-panel,
                              .toggle-chart {
                                display: none;
                              }
                            }
                            .chart-area {
                              .matrix-chart {
                                .chart-container {
                                  .domain-chart-container {
                                    .scrollable-chart {
                                      height: max-content !important;
                                      #render-proficiency-matrix {
                                        height: max-content !important;
                                        #skyline-group,
                                        #gradeline-group {
                                          filter: url(#back-shadow);
                                          polyline {
                                            fill: none;

                                            &.hidden-line {
                                              display: none;
                                            }
                                          }

                                          &#skyline-group polyline {
                                            stroke-width: 6px;
                                            stroke: $white !important;
                                            animation: polylineDash 8s linear
                                              forwards;
                                          }

                                          &#gradeline-group polyline {
                                            stroke-width: 3px;
                                            stroke: #8fd383 !important;
                                            animation: polylineDash 4s linear
                                              forwards;
                                          }

                                          @keyframes polylineDash {
                                            to {
                                              stroke-dashoffset: 0;
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        .competencies-list {
                          margin: auto;
                          .subject-name {
                            font-weight: bold;
                          }
                          .domain-name {
                            display: flex;
                            padding-left: 15px;
                            span {
                              padding: 3px;
                            }
                          }
                        }
                      }
                      .proficiency-tags {
                        .last-updated {
                          text-align: right;
                          margin-top: 15px;
                          margin-right: 30px;
                          font-size: 12px;
                        }
                        .proficiency-legends {
                          margin: 15px auto auto;
                          width: 100%;
                          display: grid;
                          grid-template-columns: repeat(6, 1fr);
                          justify-content: space-around;
                          .legend {
                            display: flex;
                            justify-content: center;
                            .box {
                              display: block;
                              width: 15.6px;
                              height: 15.6px;
                              transform: rotate(90deg);
                              margin-right: 8.4px;
                            }
                            .box-class-grade-line {
                              width: 18px;
                              height: 5px;
                              margin-top: 4px;
                              box-shadow: 0 2px 4px 0
                                $navbar-default-toggle-icon-bar-bg !important;
                              margin-right: 8.4px;
                            }
                            .box-skyline {
                              width: 18px;
                              height: 10px;
                              box-shadow: 0 2px 4px 0
                                $navbar-default-toggle-icon-bar-bg !important;
                              margin-right: 8.4px;
                            }
                            .title {
                              line-height: 13px;
                              font-size: 12px;
                              color: $elephant-dark !important;
                            }
                            &.mastered {
                              .box {
                                background-color: $blue-mastery !important;
                              }
                            }
                            &.in-progress {
                              .box {
                                background-color: $blue-in-progress !important;
                              }
                            }
                            &.not-started {
                              .box {
                                background-color: $not-started !important;
                              }
                            }
                            &.show-gut {
                              .box {
                                background-image: linear-gradient(
                                  to bottom,
                                  $gray-82,
                                  $dark-400
                                ) !important;
                              }
                            }
                            &.skyline {
                              .box-skyline {
                                background-color: $white !important;
                              }
                            }
                            &.class-grade-line {
                              .box-class-grade-line {
                                background-color: #8fd383 !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .report-summery,
        .student-competency {
          display: none !important;
        }
      }
    }
  }
}
